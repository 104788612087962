<template>
  <div>
    <q-form ref="editForm">
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              label="출력"
              icon="print"
              @btnClicked="printInfo" />
            <c-btn
              v-show="editable && isWorking && !disabled"
              :url="completeUrl"
              :isSubmit="isComplete"
              :param="fireFightingPermit"
              mappingType="PUT"
              label="완료"
              icon="check"
              @beforeAction="completeInfo"
              @btnCallback="completeCallback" />
            <c-btn
              v-show="editable && !disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="fireFightingPermit"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-plant
              :editable="editable"
              :disabled="true"
              type="edit"
              name="plantCd"
              v-model="fireFightingPermit.plantCd" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              :editable="editable"
              :disabled="true"
              name="permitDt"
              label="허가일시"
              v-model="fireFightingPermit.permitDt" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-field
              :editable="editable"
              :disabled="true"
              :data="fireFightingPermit"
              deptValue="workManagerDeptCd"
              type="dept_user"
              name="workManagerId"
              label="작업책임자"
              v-model="fireFightingPermit.workManagerId" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-field
              :editable="editable"
              :disabled="disabled || disabledWorkPermit"
              :data="fireFightingPermit"
              deptValue="fireSafetyManagerDeptCd"
              type="dept_user"
              name="fireSafetyManagerId"
              label="소방안전관리자"
              v-model="fireFightingPermit.fireSafetyManagerId" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-field
              :editable="editable"
              :disabled="disabled || disabledWorkPermit"
              :data="fireFightingPermit"
              deptValue="fireWatchmanDeptCd"
              type="dept_user"
              name="fireWatchmanId"
              label="화재감시자"
              v-model="fireFightingPermit.fireWatchmanId" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              :editable="editable"
              :disabled="true"
              label="작업장소"
              name="workLocation"
              v-model="fireFightingPermit.workLocation">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :editable="editable"
              :disabled="true"
              label="작업개요"
              name="workSummary"
              v-model="fireFightingPermit.workSummary">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              :editable="editable"
              :disabled="true"
              label="허가번호"
              name="permitNo"
              v-model="fireFightingPermit.permitNo">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-checkbox
              :editable="editable"
              :disabled="disabled || disabledWorkPermit"
              :isArray="false"
              codeGroupCd="SOP_FIRE_WORK_CLASSIFICATION"
              valueText="codeName"
              valueKey="code"
              label="작업구분"
              name="fireWorkClassification"
              v-model="fireFightingPermit.fireWorkClassification"
              @datachange="workClassChange"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              :editable="editable"
              :disabled="disabled || disabledWorkPermit || disableEtc"
              label="작업구분(기타)"
              name="fireWorkClassificationEtc"
              v-model="fireFightingPermit.fireWorkClassificationEtc">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <c-textarea
              :disabled="disabled"
              :editable="editable"
              :rows="2"
              label="비고" 
              name="remarks" 
              v-model="fireFightingPermit.remarks" />
          </div>
        </template>
      </c-card>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-table
            title="화기 체크리스트"
            :columns="gridChecklist.columns"
            :gridHeight="gridChecklist.height"
            :data="fireChecklistResults"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="(editable&&!disabled) || isEnvSafDept"
          >
            <template v-slot:customArea="{ props, col }">
              <template v-if="props.row['textFlag']==='N'">
                <q-checkbox
                  dense
                  color="orange-custom"
                  class="tableCheckBox"
                  :disable="!(editable&&!disabled)"
                  true-value="O"
                  false-value="X"
                  v-model="props.row[col.name]"
                  @input="val => datachange(props, col)">
                </q-checkbox>
              </template>
              <template v-else>
                <c-text-column
                  :editable="editable"
                  :disable="disabled"
                  :col="col"
                  :props="props"
                  @datachange="datachange(props, col)"
                />
              </template>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div style="height:0">
            <q-banner v-if="!confiFlag" inline-actions class="text-white bg-red fire-fighting-permit-table-info-banner">
              <template v-slot:avatar>
                <q-icon name="info_outline" color="white" />
              </template>
              밀폐 보충작업을 하지 않는 허가서입니다.
            </q-banner>
          </div>
          <c-table
            title="밀폐 체크리스트"
            :columns="gridChecklist.columns"
            :gridHeight="gridChecklist.height"
            :data="confiChecklistResults"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="(editable&&!disabled) || isEnvSafDept"
          >
            <template v-slot:customArea="{ props, col }">
              <template v-if="props.row['textFlag']==='N'">
                <q-checkbox
                  dense
                  color="orange-custom"
                  class="tableCheckBox"
                  :disable="!(editable&&!disabled)"
                  true-value="O"
                  false-value="X"
                  v-model="props.row[col.name]"
                  @input="val => datachange(props, col)">
                </q-checkbox>
              </template>
              <template v-else>
                <c-text-column
                  :editable="editable"
                  :disable="disabled && !isEnvSafDept"
                  :col="col"
                  :props="props"
                  @datachange="datachange(props, col)"
                />
              </template>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-table
            title="화기 안전조치 요구사항"
            :columns="gridFire.columns"
            :gridHeight="gridFire.height"
            :data="fireFightingPermit.fireCheckResults"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="editable&&!disabled&&!disabledWorkPermit"
            :contentsField="contentsField"
            @table-data-change="tableDataChange"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <div style="height:0">
            <q-banner v-if="!confiFlag" inline-actions class="text-white bg-red fire-fighting-permit-table-info-banner">
              <template v-slot:avatar>
                <q-icon name="info_outline" color="white" />
              </template>
              밀폐 보충작업을 하지 않는 허가서입니다.
            </q-banner>
          </div>
          <c-table
            title="밀폐 안전조치 요구사항"
            :columns="gridConfinedSpace.columns"
            :gridHeight="gridConfinedSpace.height"
            :data="fireFightingPermit.confinedSpaceCheckResults"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="editable&&!disabled&&!disabledWorkPermit&&confiFlag"
            :contentsField="contentsField"
            @table-data-change="tableDataChange"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-table
            title="작업종료 후 안전조치 (작업종료 후 작성 → 반납)"
            :columns="gridPermitConfirm.columns"
            :gridHeight="gridPermitConfirm.height"
            :data="gridPermitConfirm.data"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="false"
          >
          </c-table>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingPermitDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopWorkPermitId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      fireFightingPermit: {
        sopFireFightingPermitId: '',  // 소방공사관리 일련번호
        sopWorkPermitId: '',  // 작업허가서 일련번호
        permitNo: '',  // 허가번호_(YYYYMMDD-01)
        permitDt: '',  // 허가일
        plantCd: '', // 사업장
        workSummary: '',  // 작업개요
        workManagerId: '', // 담당자
        workLocation: '', // 작업장소
        swpStepCd: '', // 진행상태
        fireWatchmanId: '',  // 화재감시자
        fireWorkClassification: '',  // 화기 작업구분
        fireWorkClassificationEtc: '',  // 화기 작업구분
        fireSafetyManagerId: '',  // 소방안전관리자
        confirmDate: '',  // 확인일자
        sopFireFightingPermitStepCd: '',  // 소방공사관리 진행단계
        remarks: '',  // 비고
        confiFlag: '',
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        checklistResults: [],
        fireCheckResults: [],
        confinedSpaceCheckResults: [],
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
      },
      gridChecklist: {
        columns: [
          {
            name: 'checklistItemName',
            field: 'checklistItemName',
            label: '점검내용',
            align: 'left',
            sortable: true,
          },
          {
            name: 'checklistResult',
            field: 'checklistResult',
            label: '결과',
            align: 'center',
            sortable: false,
            style: 'width:100px',
            type: 'custom',
          },
        ],
        height: '420px'
      },
      gridFire: {
        columns: [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '항목',
            align: 'left',
            sortable: true,
          },
          {
            name: 'necessaryFlag',
            field: 'necessaryFlag',
            label: '필요',
            align: 'center',
            sortable: false,
            type: 'check',
            'true': 'Y',
            'false': 'N',
            value: 'N',
            setHeader: true,
          },
          {
            name: 'confirmFlag',
            field: 'confirmFlag',
            label: '확인',
            align: 'center',
            sortable: false,
            type: 'check',
            disableTarget: 'necessaryFlag',
            disableCon: 'Y',
            'true': 'Y',
            'false': 'N',
            value: 'N',
            setHeader: true,
          },
        ],
        height: '580px'
      },
      gridConfinedSpace: {
        columns: [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '항목',
            align: 'left',
            sortable: true,
          },
          {
            name: 'necessaryFlag',
            field: 'necessaryFlag',
            label: '필요',
            align: 'center',
            sortable: false,
            type: 'check',
            'true': 'Y',
            'false': 'N',
            value: 'N',
            setHeader: true,
          },
          {
            name: 'confirmFlag',
            field: 'confirmFlag',
            label: '확인',
            align: 'center',
            sortable: false,
            type: 'check',
            disableTarget: 'necessaryFlag',
            disableCon: 'Y',
            'true': 'Y',
            'false': 'N',
            value: 'N',
            setHeader: true,
          },
        ],
        height: '580px'
      },
      gridPermitConfirm: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '확인사항',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
        height: '580px'
      },
      editable: true,
      isSave: false,
      isComplete: false,
      detailUrl: '',
      listCheckItemUrl: '',
      printUrl: '',
      saveUrl: '',
      completeUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
    isOld() {
      return Boolean(this.fireFightingPermit.sopFireFightingPermitId)
    },
    disabledWorkPermit() {
      return this.fireFightingPermit.swpStepCd !== 'SS00000001'
        // 결재중인 경우 비활성화
        || this.fireFightingPermit.approvalStatusCd === 'ASC0000001'
    },
    disabled() {
      return this.fireFightingPermit.sopFireFightingPermitStepCd === 'FFP0000005'
        // 결재중인 경우 비활성화
        || this.fireFightingPermit.approvalStatusCd === 'ASC0000001'
    },
    isWorking() {
      // 작성중이 아닌경우
      return this.fireFightingPermit.swpStepCd !== 'SS00000001'
    },
    confiFlag() {
      return this.fireFightingPermit.confiFlag === '1'
    },
    disableEtc() {
      return this.fireFightingPermit.fireWorkClassification ? 
        this.fireFightingPermit.fireWorkClassification.indexOf('FWC0000020') === -1 : true
    },
    fireChecklistResults() {
      let data = [];
      if (this.fireFightingPermit.checklistResults 
        && this.fireFightingPermit.checklistResults.length > 0) {
          data = this.$_.filter(this.fireFightingPermit.checklistResults, { supplementWorkTypeCd: '' })
      }
      return data
    },
    confiChecklistResults() {
      let data = [];
      if (this.confiFlag
        && this.fireFightingPermit.checklistResults 
        && this.fireFightingPermit.checklistResults.length > 0) {
          data = this.$_.filter(this.fireFightingPermit.checklistResults, { supplementWorkTypeCd: 'SSWT000001' })
      }
      return data
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFightingPermit.get.url
      this.listCheckItemUrl = selectConfig.sop.swp.workChekItem.list.url
      this.printUrl = selectConfig.fft.fireFightingPermit.print.url
      this.saveUrl = transactionConfig.fft.fireFightingPermit.save.url
      this.completeUrl = transactionConfig.fft.fireFightingPermit.complete.url
      // code setting
      this.$comm.getComboItems('SOP_FIRE_FIGHTING_PERMIT_COMFIRM').then(_result => {
        this.gridPermitConfirm.data = _result;
      });
      // list setting
      this.getDetail();
    },
    getDetail() {
      /**
       * 작업허가서에서 출발함으로 데이터가 없는지를 확인하지 않음
       */
      this.$http.url = this.$format(this.detailUrl, this.popupParam.sopWorkPermitId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.fireFightingPermit, _result.data)

        this.getCheckItemList();
      },);
    },
    getCheckItemList() {
      /**
       * default 값을 조회
       * 
       * 초기인 경우 workPermit.checkResults에 값을 그대로 적용
       * 작성중 단계인 경우 default 값에 새로운 항목이 있을시에 workPermit.checkResults에 추가하여 입력을 받을 수 있도록 조치
       * 승인완료, 연장, 취소, 작업완료인 경우 저장된 데이터를 그대로 표시하며 이 경우 default값을 조회하지 않도록 조치
       */
      this.setDefaultItems('SWT0000005'); // 화기
      if (this.confiFlag) {
        this.setDefaultItems('SWT0000010'); // 밀폐
      }
    },
    setDefaultItems(sopWorkTypeCd) {
      if (!this.fireFightingPermit.plantCd) return;
      if (!this.fireFightingPermit.fireCheckResults) this.fireFightingPermit.fireCheckResults = [];
      if (!this.fireFightingPermit.confinedSpaceCheckResults) this.fireFightingPermit.confinedSpaceCheckResults = [];
      if (!this.isOld || this.fireFightingPermit.swpStepCd === 'SS00000001') {
        this.$http.url = this.listCheckItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.fireFightingPermit.plantCd,
          sopWorkTypeCd: sopWorkTypeCd,
          useFlag: 'Y',
        }
        this.$http.request((_result) => {
          if (sopWorkTypeCd === 'SWT0000005') {
            if (!this.isOld && (!this.fireFightingPermit.fireCheckResults || this.fireFightingPermit.fireCheckResults.length === 0)) {
              // 최초인 경우 조치항목을 가지고 있지 않음으로 해당 값을 표시
              this.$_.forEach(_result.data, item => {
                this.fireFightingPermit.fireCheckResults.push({
                  sopWorkPermitId: '',  // 작업허가서 일련번호
                  supplementWorkTypeCd: '',  // 보충작업구분
                  sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                  checkItemName: item.checkItemName, // 점검항목명
                  sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                  necessaryFlag: 'N',  // 필요
                  confirmFlag: 'N',  // 확인
                  confirmUserId: '',  // 확인자
                  confirmUserName: '',  // 확인자명
                  regUserId: this.$store.getters.user.userId,  // 등록자 ID
                  chgUserId: '',  // 수정자 ID
                  editFlag: 'C'
                })
              })
            } else {
              this.$_.forEach(_result.data, item => {
                if (this.$_.findIndex(this.fireFightingPermit.fireCheckResults, { sopCheckItemId: item.sopCheckItemId }) === -1) {
                  this.fireFightingPermit.fireCheckResults.push({
                    sopWorkPermitId: this.fireFightingPermit.sopWorkPermitId,  // 작업허가서 일련번호
                    supplementWorkTypeCd: '',  // 보충작업구분
                    sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                    checkItemName: item.checkItemName, // 점검항목명
                    sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                    necessaryFlag: 'N',  // 필요
                    confirmFlag: 'N',  // 확인
                    confirmUserId: '',  // 확인자
                    confirmUserName: '',  // 확인자명
                    regUserId: this.$store.getters.user.userId,  // 등록자 ID
                    chgUserId: '',  // 수정자 ID
                    editFlag: 'C'
                  })
                }
              })
            }
          } else {
            if (!this.isOld && (!this.fireFightingPermit.confinedSpaceCheckResults || this.fireFightingPermit.confinedSpaceCheckResults.length === 0)) {
              // 최초인 경우 조치항목을 가지고 있지 않음으로 해당 값을 표시
              this.$_.forEach(_result.data, item => {
                this.fireFightingPermit.confinedSpaceCheckResults.push({
                  sopWorkPermitId: '',  // 작업허가서 일련번호
                  supplementWorkTypeCd: 'SSWT000001',  // 보충작업구분
                  sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                  checkItemName: item.checkItemName, // 점검항목명
                  sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                  necessaryFlag: 'N',  // 필요
                  confirmFlag: 'N',  // 확인
                  confirmUserId: '',  // 확인자
                  confirmUserName: '',  // 확인자명
                  regUserId: this.$store.getters.user.userId,  // 등록자 ID
                  chgUserId: '',  // 수정자 ID
                  editFlag: 'C'
                })
              })
            } else {
              this.$_.forEach(_result.data, item => {
                if (this.$_.findIndex(this.fireFightingPermit.confinedSpaceCheckResults, { sopCheckItemId: item.sopCheckItemId }) === -1) {
                  this.fireFightingPermit.confinedSpaceCheckResults.push({
                    sopWorkPermitId: this.fireFightingPermit.sopWorkPermitId,  // 작업허가서 일련번호
                    supplementWorkTypeCd: 'SSWT000001',  // 보충작업구분
                    sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                    checkItemName: item.checkItemName, // 점검항목명
                    sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                    necessaryFlag: 'N',  // 필요
                    confirmFlag: 'N',  // 확인
                    confirmUserId: '',  // 확인자
                    confirmUserName: '',  // 확인자명
                    regUserId: this.$store.getters.user.userId,  // 등록자 ID
                    chgUserId: '',  // 수정자 ID
                    editFlag: 'C'
                  })
                }
              })
            }
          }
        },);
      }
    },
    contentsField(props, col) {
      /**
       * 안전조치사항 항목에서 항목 입력 타입이 '테이블_점검자' 인 경우 테이블에 표시
       * 단, 예전에 '테이블_점검자'였으며 그럼으로 인해 데이터가 저장이 된적이 있는 경우 컴포넌트 표시
       */
      let returnVal = true;
      if (col.name === 'confirmUserName') {
        returnVal = props.row['sopCheckItemKindCd'] === 'SCIK000005' || Boolean(props.row['confirmUserName'])
      }
      return returnVal
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    tableDataChange(props, col) {
      if (col.name === 'necessaryFlag' && props.row[col.name] !== 'Y') {
        this.$set(props.row, 'confirmFlag', 'N');
      }
    },
    workClassChange() {
      if (this.disableEtc) {
        this.$set(this.fireFightingPermit, 'fireWorkClassificationEtc', '')
      }
    },
    saveInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.fireFightingPermit.regUserId = this.$store.getters.user.userId;
              this.fireFightingPermit.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.fireFightingPermit.sopFireFightingPermitId = result.data
      this.getDetail();
    },
    completeInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.fireFightingPermit.chgUserId = this.$store.getters.user.userId;

              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.fireFightingPermit.sopFireFightingPermitId = result.data
      this.getDetail();
    },
    printInfo() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.popupParam.sopWorkPermitId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = '[소방공사] ' + this.fireFightingPermit.workSummary + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>
<style scoped>
.fire-fighting-permit-table-info-banner {
  position: relative;
  top: 150px;
  left: 50%;
  transform: translate(-50%);
  width: 80%;
  z-index: 1000;
}
</style>